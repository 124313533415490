/** 
 * AR解决方案
 */
<template>
  <v-card class="solutionAR">
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerSolutionVR400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <!-- <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="800" class="d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="font-size-60 mt-8 font-weight-bold justify-center">
                  虚拟数字化全景解决方案
                </v-card-title>
                <v-card-title class="subtitle-1 font-weight-medium justify-center">
                  虚拟、超沉浸体验，引领品牌与消费者互动新纪元，重塑品牌影响力
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container> -->
      </v-img>
    </v-banner>
    <!-- VR解决方案 -->
    <v-card class="justify-center" :flat="true">
      <div class="public-title">
        <p>INDUSTRY SOLUTIONS</p>
        <div>
          <span>深耕细分场景，提供更加垂直的行业解决方案</span>
          <span>VR技术与各行业结合 匹配品牌风格 提供行业解决方案</span>
        </div>
      </div>
      <v-tabs centered color="#0084ff" class="g-tabs mt-5" v-model="tab">
        <v-tab v-for="(item,ind) in items" :key="ind">
          {{item.h1}}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item transition="fade-transition"
          v-for="(item,k) in items"
          :key="k"
        >
         <div class="box">
           <transition name="slide-fade" appear >
            <div class="left" v-show="tab == k"><img :src='item.img' /></div>
           </transition>
           <transition name="slide-fade-reverse" appear >
            <div class="right" v-show="tab == k">
              <v-card-text class="title" v-html="item.title">{{item.title}}</v-card-text>
              <v-card-text class="tip pt-2" v-html="item.content">{{item.content}}</v-card-text>
            </div>
           </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
   
    <!-- 一站式VR营销服务 -->
    <v-card :flat="true" class="serve">
      <div class="public-title">
        <p>VR MARKETING SERVICES</p>
        <div>
          <span>一站式VR营销服务</span>
          <span>打造集全集展示、营销转化、线上购物一体的全景互动营销系统  </span>
        </div>
      </div>
      <v-container  class="d-flex justify-md-space-around mt-9">
        <div class="carousel-container">
          <div class="c-item-1 box-shadow" :class="currentHover === 1 ? 'c-item-hover':''" @mouseover="currentHover = 1;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-2 box-shadow" :class="currentHover === 2 ? 'c-item-hover':''" @mouseover="currentHover = 2;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-3 box-shadow" :class="currentHover === 3 ? 'c-item-hover':''" @mouseover="currentHover = 3;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-4 box-shadow" :class="currentHover === 4 ? 'c-item-hover':''" @mouseover="currentHover = 4;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-5 box-shadow" :class="currentHover === 5 ? 'c-item-hover':''" @mouseover="currentHover = 5;">
            <div class="c-item-cover"></div>
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- 虚拟数字 -->
    <v-card class="justify-center wap_num" :flat="true">
      <div class="public-title">
        <p>VIRTUAL NUMBER</p>
        <div>
          <span>虚拟数字场景帮您找到客户 留住客户</span>
          <span>用全景让品牌深入人心，增强品牌影响力  </span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="4" v-for="n in CardsNum" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <v-img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

     <!-- 精品模板 -->
    <v-card class="justify-center wap3" :flat="true">
      <div class="public-title">
        <p>BOUTIQUE TEMPLATE</p>
        <div>
          <span>精品模板 丰富你的创作灵感</span>
          <span>行业模板匹配品牌风格，提供行业解决方案  </span>
        </div>
      </div>
      <div class="con">
        <v-row>
          <v-col
            v-for="n in cards"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div>
                {{ n.tit }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-card>
</template>

<script>
let _this;
export default {
  name: "solutionAR",
  data(){
    return{
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      active:0,
      //虚拟数字
      CardsNum: [
        { id:1, src: require('../../../../public/img/icons/solutionVR/VRicon1.png'), tit:'强互动 重体验',content:'720°沉浸式体验+数字多媒体互动打造独特品牌形象'},
        { id:2,  src: require('../../../../public/img/icons/solutionVR/VRicon2.png'), tit:'拓客引流、拉新' ,content:'微信、H5、Web多渠道流量获客，搞定营销获客难题，拉新获客不用愁'},
        { id:3,  src: require('../../../../public/img/icons/solutionVR/VRicon3.png'), tit:'丰富营销礼赠场景',content:'海量营销场景，满足活动所需，让您与客户的距离再近一点' },
        { id:4,  src: require('../../../../public/img/icons/solutionVR/VRicon4.png'), tit:'同屏连线 高效营销',content:'远程交互，异地实时销售配合智能解说 ，随时解除用户疑虑' },
        { id:5,  src: require('../../../../public/img/icons/solutionVR/VRicon5.png'), tit:'强势引流、高转化',content:'优惠券、门店券、积分等多种权益体系，为店铺强势引流' },
        { id:6,  src: require('../../../../public/img/icons/solutionVR/VRicon6.png'), tit:'完善的开发者生态',content:'稳定的API对接，为商户提供更过服务选择' },
      ],
      //精品模板
      cards: [
        { id:1, src: 'https://h5.ophyer.cn/official_website/other/VRmb1.png', tit:'全景商城模板'},
        { id:2,  src: 'https://h5.ophyer.cn/official_website/other/VRmb2.png', tit:'全景房产模板'},
        { id:3,  src: 'https://h5.ophyer.cn/official_website/other/VRmb3.png', tit:'全景汽车模板'},
        { id:4,  src: 'https://h5.ophyer.cn/official_website/other/VRmb4.png', tit:'智慧文旅模板'},
        { id:5,  src: 'https://h5.ophyer.cn/official_website/other/learn1-1.jpg', tit:'智慧教育模板'},
        { id:6,  src: 'https://h5.ophyer.cn/official_website/other/VRmb6.png', tit:'全景餐饮模板'},
        { id:7,  src: 'https://h5.ophyer.cn/official_website/other/VRmb7.png', tit:'全景酒店模板'},
        { id:8,  src: 'https://h5.ophyer.cn/official_website/other/VRmb8.png', tit:'游戏模板'},
      ],
      vtab: null,
      //解决方案
      items: [
        {id:1, h1:'电商零售', title:"轻松操作打造全景云店<br/>助力营销转化", content:'超沉浸体验、虚拟导购及线上线下引流，为营销<br/>活动持续蓄力',img:'https://h5.ophyer.cn/official_website/other/VR2-1.png'},
        {id:2, h1:'房产家居', title:"3D实景复刻，还原真实空间<br/>房屋信息一览无遗", content:'720°全景看房+沙盘+智能解说+同屏连线，随<br/>时随地高效看房',img:'https://h5.ophyer.cn/official_website/other/VR3-1.png'},
        {id:3, h1:'智慧文旅', title:"数字科技+文化创意<br/>让景区焕发全新生命力", content:'通过AR/VR技术手段，发展沉浸式互动文旅消费内容，<br/>推动景区数字化转型升级',img:'https://h5.ophyer.cn/official_website/other/VR6-1.png'},
        {id:4, h1:'智慧教育', title:"VR沉浸式+强交互情景教学<br/>参与感更强，提升教学质量", content:'涵盖基础教育、高等教育、社会培训等多领域的智慧教<br/>育平台',img:'https://h5.ophyer.cn/official_website/other/VR4-1.png'},
        {id:5, h1:'汽车行业', title:"汽车高模结合VR技术<br/>带来线上看车新体验", content:'高精度建模，702°可交互查看汽车外观及内饰<br/>支持添加留咨表单，为门店强势引流',img:'https://h5.ophyer.cn/official_website/other/VR5.png'},
        {id:6, h1:'餐饮行业', title:"打破传统餐饮行业营销方式<br/>让你的营销活动游刃有余", content:'适配主流餐饮、外卖平台，为线上、线下餐饮店铺强势引流',img:'https://h5.ophyer.cn/official_website/other/VR1-1.png'},
      ],
      //营销服务
      serviceList: [
        {
          img: require('../../../../public/img/icons/solutionVR/VRicon21.png'),
          width: 500,
          imgBig: 'https://h5.ophyer.cn/official_website/other/VRserve1.png',
          title: '可视化编辑器',
          big_conten: 'VR模式浏览、自定义视角等，720度全景体验触手可及'
        },
        {
          img: require('../../../../public/img/icons/solutionVR/VRicon22.png'),
          width: 200,
          imgBig: 'https://h5.ophyer.cn/official_website/other/VRserve2.png',
          title: '富媒体展示',
          big_conten: '图片、音乐、视频、智能地图、语音介绍等，支持全素材上传'
        },
        {
          img: require('../../../../public/img/icons/solutionVR/VRicon23.png'),
          width: 200,
          imgBig: 'https://h5.ophyer.cn/official_website/other/VRserve3.png',
          title: '场景购物',
          big_conten: '嵌入外部商品链接，优惠券，实现场景内购物'
        },
        {
          img: require('../../../../public/img/icons/solutionVR/VRicon24.png'),
          width: 200,
          imgBig: 'https://h5.ophyer.cn/official_website/other/VRserve4.png',
          title: '720°环视',
          big_conten: '720°环视，浏览每个角落，支持多媒体融合互动'
        },
        {
          img: require('../../../../public/img/icons/solutionVR/VRicon25.png'),
          width: 200,
          imgBig: 'https://h5.ophyer.cn/official_website/other/VRserve5.png',
          title: '多种分享方式',
          big_conten: '方便、快捷、高效分享。可嵌入到网页、微信、支付宝小程序等'
        },
      ],
      currentHover: 1,
    }
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    mouseOver(ind){
      this.active=ind
    },
    mouseOut(){
      this.active=0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__subtitle,.v-card__text, .text-subtitle-1{
  line-height: 26px !important;
}
.box-shadow{
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
}
.serve{
  margin: 0 auto;
  .v-card__title{
    margin-top: 27px;
    padding: 6px 16px !important;
  }
  .v-card__text{
    line-height: 20px !important;
    padding: 6px 16px !important;
  }
  .v-card__title{
    width: 150px !important;
  }
  .public-title{
    margin-top: 22px;
  }
  .carousel-container {
    width: 1200px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    .c-item-hover{
      width: 560px;
    }
    .c-item-1{
      background-image: url(https://h5.ophyer.cn/official_website/other/VRserve_1.jpg);
      margin-right: 10px;
      background-size: cover;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-2{
      background-image: url(https://h5.ophyer.cn/official_website/other/VRserve_2.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-2:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-3{
      background-image: url(https://h5.ophyer.cn/official_website/other/VRserve_3.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-3:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-4{
      background-image: url(https://h5.ophyer.cn/official_website/other/VRserve_4.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-4:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-5{
      background-image: url(https://h5.ophyer.cn/official_website/other/VRserve_5.jpg);
      background-size: cover;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-5:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-cover {
      width: 150px;
      height: 400px;
      border-radius: 14px;
      opacity: .6;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-1.c-item-cover,.c-item-2.c-item-cover,.c-item-3.c-item-cover,.c-item-4.c-item-cover{
      //background-position: 50%;
    }
  }
  //@keyframes section-fade-in {
  //  0% {
  //    opacity: 0;
  //    transform: translate3d(0, 10%, 0);
  //  }
  //  100% {
  //    opacity: 1;
  //    transform: translateZ(0);
  //  }
  //}
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.warp {
  width: 1200px;
  div {
    text-align: center !important;
  }
  img{
    border-radius: 15px;
  }
}
.wap_num{
  .col-4{
    height: 197px !important;
  }
}
.box {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit:cover
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
      color: #666;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0 auto;
      width:120px ;
      height: 120px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      // padding: 0px 45px;
      line-height: 20px;
    }
}
.iconWrap{
  width:450px; 
  height:600px; 
  position: relative;
  .icon_one{
    position: absolute;
    top: 30%;
  }
  .icon_two{
    position: absolute;
    bottom: 10%;
  }
}

.serve-box {
  // transition: width 0.3s;
  width: 300px;
  height: 400px;
  margin: 50px auto;
}
.serve-box2 {
  // transition: width 0.3s;
  width: 120px;
  height: 400px;
  margin: 50px auto;
  
}

.wap3{
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
  .public-title {
    margin-top: 107px !important;
  }
.con {
    margin-top: 45px;
    .box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

</style>